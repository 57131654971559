<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'商品'"
    >
      <div slot="toolSub">
        <el-button
          type="success"
          :loading="bntLoading"
          icon="el-icon-plus"
          @click="synchronism"
          >同步默认商品</el-button
        >
      </div>
      <div class="search" slot="tool">
        <el-input
          placeholder="根据标题查询"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">标题</template>
        </el-input>

        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">类别</div>
          <el-select
            filterable
            v-model="searchQuery.typeId"
            clearable
            placeholder="根据类别筛选"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    let self = this;
    return {
      remote: api.common,
      apiName: "/market/commodity",
      searchVal: "",
      jobList: [],
      typeList: [],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { name: "", typeId: "" },
      rules: {
        //传递给APPlist的表单验证
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        unitId: [
          {
            required: true,
            message: "请选择单位",
            trigger: ["blur", "change"],
          },
        ],
        typeId: [
          {
            required: true,
            message: "请选择类别",
            trigger: ["blur", "change"],
          },
        ],
        imagePath: [
          {
            required: true,
            message: "请上传图片",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "商品名称",
          prop: "name",
          align: "center",
        },

        {
          label: "图片",
          prop: "imagePath",
          align: "center",
          type: "image",
        },
        {
          label: "类别",
          prop: "typeName",
          align: "center",
        },
        {
          label: "是否推荐",
          prop: "isRecommend",
          align: "center",
          type: "switch",
          formatter: function() {
            const switchInfo = {
              handler: function(row) {
                self.handleSwitch(row);
              },
            };

            return switchInfo;
          },
        },
      ],
      formProps: [
        {
          label: "商品名称",
          prop: "name",
          type: "input",
        },
        {
          label: "单位",
          prop: "unitId",
          type: "select",
          selectData: [],
        },

        {
          label: "类别",
          prop: "typeId",
          type: "cascader",
          selectData: [],
        },
        {
          label: "图片",
          prop: "imagePath",
          type: "upload",
        },
      ],
    };
  },
  created() {
    this.getSelectList();

    this.getUnitList();
  },
  methods: {
    //同步默认商品到商户

    synchronism() {
      this.bntLoading = true;
      api.common
        .get({
          apiName: "/merchant/businessVariety/setMerchantDefaultProducts",
        })
        .then((res) => {
          this.$message({ message: res.message, type: "success" });
          this.bntLoading = false;
        });
    },

    handleSwitch(obj) {
      let newObj = Object.assign({}, obj, {
        apiName: "/market/commodity/update",
      });
      newObj.isRecommend = parseInt(newObj.isRecommend);
      api.common.post(newObj);
    },
    getUnitList() {
      api.dictionary.getAllList({ type: "商品单位" }).then((res) => {
        this.formProps[1].selectData = res.data;
      });
    },
    async getSelectList() {
      let treeArr = [];
      await api.businessType.getTreeList().then((res) => {
        let arr = res.data;
        arr.forEach((item) => {
          this.typeList.push({ value: item.id, label: item.name });
          if (item.child) {
            let children = [];
            item.child.forEach((items) => {
              children.push({
                value: items.id,
                label: items.name,
              });
            });
            treeArr.push({
              value: item.id,
              label: item.name,
              children: children,
            });
          } else {
            treeArr.push({
              value: item.id,
              label: item.name,
            });
          }

          this.formProps[2].selectData = treeArr;
        });
        console.log("tree", this.typeList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
